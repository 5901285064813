import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#0e979b',
        dark: '#18283f',
        light: '#fafbfc',
      },
    },
  },
  options: {
    customProperties: true,
  },
});
