import Header from '@/components/home/Header/Header.vue';
import About from '@/components/home/About/About.vue';
import Services from '@/components/home/Services/Services.vue';
import Clients from '@/components/home/Clients/Clients.vue';
import Company from '@/components/home/Company/Company.vue';

export default {
  components: {
    Header,
    About,
    Services,
    Clients,
    Company,
  },
  methods: {
    showSuccess(e) {
      this.$emit('showSuccess', e);
    },
    showError(e) {
      this.$emit('showError', e);
    },
  },
};
