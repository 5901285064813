<template>
  <v-layout class="background-light">
    <v-container class="mx-auto pa-md-15 pa-sm-3">
      <section class="d-flex justify-center flex-wrap">
        <div class="card dark--text" data-aos="fade-right">
          <div class="round-icon">
            <ServicesIconsCPU />
          </div>
          <div class="card-divider mt-5 mb-3"></div>
          <h5 class="card-title">Applications</h5>
          <p class="card-text">
            Chaque projet est unique. Nous avons un service-conseil afin de bien
            comprendre vos attentes et vos besoins afin de créer le meilleur de
            vos idées.
          </p>
        </div>

        <div class="card dark--text" data-aos="fade-right">
          <div class="round-icon">
            <ServicesIconsWeb />
          </div>
          <div class="card-divider mt-5 mb-3"></div>
          <h5 class="card-title">Web</h5>
          <p class="card-text">
            Le Web est vaste. Les technologies du Web permettent un usage
            multi-plate-forme et une multitude d'écrans donc facilite l'usage des
            utilisateurs.
          </p>
        </div>

        <div class="card dark--text" data-aos="fade-right">
          <div class="round-icon">
            <ServicesIconsMobile />
          </div>
          <div class="card-divider mt-5 mb-3"></div>
          <h5 class="card-title">Mobile</h5>
          <p class="card-text">
            Il n'a jamais été aussi facile de partager une application. Nous
            avons un ordinateur ultra-puissance qui nous suit partout. 90% du
            web se consume sur un téléphone.
          </p>
        </div>

        <div class="card dark--text" data-aos="fade-right">
          <div class="round-icon">
            <ServicesIconsPrototype />
          </div>
          <div class="card-divider mt-5 mb-3"></div>
          <h5 class="card-title">Prototype</h5>
          <p class="card-text">
            Besoin de prototype 3D de votre projet? Nous les créons pour vous.
          </p>
        </div>

        <div class="card dark--text" data-aos="fade-right">
          <div class="round-icon">
            <ServicesIconsIndustry />
          </div>
          <div class="card-divider mt-5 mb-3"></div>
          <h5 class="card-title">Industrie 4.0</h5>
          <p class="card-text">
            Expertise dans l'Industrie 4.0. Vous voulez découvrir les
            révolutions de l'Industrie 4.0, nous aussi. Les limites de cette
            technologie sont encore très loin d'être connues et c'est avec grand
            plaisir que nous en discuterons avec vous.
          </p>
        </div>

        <div class="card dark--text" data-aos="fade-right">
          <div class="round-icon">
            <ServicesIconsFigma />
          </div>
          <div class="card-divider mt-5 mb-3"></div>
          <h5 class="card-title">Design UI/UX</h5>
          <p class="card-text">
            Création d'application adaptative et accessible. Fine pointe de la
            modernité lors de la création de projets afin de suivre les tendances
            et permettre une utilisation globale.
          </p>
        </div>
      </section>
    </v-container>
  </v-layout>
</template>

<script>
/* eslint-disable */
import ServicesIconsFigma from './Services-Icons/Services-Icons-Figma.vue';
import ServicesIconsCPU from './Services-Icons/Services-Icons-CPU.vue';
import ServicesIconsWeb from './Services-Icons/Services-Icons-Web.vue';
import ServicesIconsIndustry from './Services-Icons/Services-Icons-Industry.vue';
import ServicesIconsMobile from './Services-Icons/Services-Icons-Mobile.vue';
import ServicesIconsPrototype from './Services-Icons/Services-Icons-Prototype.vue';
/* eslint-enable */

export default {
  components: {
    ServicesIconsFigma,
    ServicesIconsCPU,
    ServicesIconsWeb,
    ServicesIconsIndustry,
    ServicesIconsMobile,
    ServicesIconsPrototype,
  },
};
</script>

<style lang="scss">
@import './Services.scss';
</style>
