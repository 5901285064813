import 'aos/dist/aos.css';

import AOS from 'aos';
import Vue from 'vue';
import VueTyperPlugin from 'vue-typer';

import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

Vue.use(VueTyperPlugin);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  created() {
    AOS.init();
  },
  render: (h) => h(App),
}).$mount('#app');
