<template>
  <div>
    <Contact v-model="showDialog" />
    <v-dialog
      v-model="sidebar"
      app
      fullscreen
      color="dark"
      class="light--text"
      transition="dialog-bottom-transition"
    >
      <v-card color="dark">
        <v-toolbar flat color="dark">
          <v-btn icon @click.native="dialog = false">
            <v-app-bar-nav-icon @click="sidebar = !sidebar">
            </v-app-bar-nav-icon>
          </v-btn>
        </v-toolbar>
        <div v-for="item in menuItems" :key="item.title">
          <v-btn
            block
            text
            :to="item.path"
            color="primary"
            class="btn-block"
            @click="sidebar = !sidebar"
          >
            {{ item.title }}
          </v-btn>
        </div>
        <v-btn
          block
          text
          color="primary"
          class="btn-block"
          @click="showContact()"
        >
          Contact
        </v-btn>
      </v-card>
    </v-dialog>

    <v-app-bar app fixed elevate-on-scroll color="dark">
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
      </span>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <v-btn
          text
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
          color="primary"
          class="menu-button"
        >
          {{ item.title }}
        </v-btn>
        <v-btn
          text
          color="primary"
          class="menu-button"
          @click="showDialog = true"
        >
          Contact
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>
  </div>
</template>

<script>
import Contact from '@/components/shared/Contact/Contact.vue';

export default {
  components: {
    Contact,
  },
  data() {
    return {
      sidebar: false,
      showDialog: false,
      menuItems: [
        { title: 'Accueil', path: '/', icon: 'home' },
        // { title: 'Blog', path: '/blog', icon: 'face' },
      ],
    };
  },
  methods: {
    showContact() {
      this.showDialog = true;
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style lang="scss">
@import './Navbar.scss';
</style>
