import Contact from '@/components/shared/Contact/Contact.vue';
import HeaderLogos from '@/components/home/Header/Header-logos/Header-logos.vue';

export default {
  components: {
    HeaderLogos,
    Contact,
  },
  data() {
    return {
      scrolledX: false,
      scrolledY: false,
      scrollSize: false,
      scrollText: false,
      showDialog: false,
      lastScrollTop: 0,
      scrollDirection: '',
      initialButtonPos: {
        top: 0,
        width: 0,
        x: 0,
        y: 0,
        fontSize: 0,
      },
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    const button = document.querySelector('.floating-button');
    this.initialButtonPos.top = button.getBoundingClientRect().top;
    this.initialButtonPos.width = button.getBoundingClientRect().width;
    this.initialButtonPos.x = button.offsetLeft;
    this.initialButtonPos.y = button.offsetTop;
    const fontSize = window
      .getComputedStyle(button, null)
      .getPropertyValue('font-size');
    this.initialButtonPos.fontSize = parseFloat(fontSize);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    showSuccess(e) {
      this.$emit('showSuccess', e);
    },
    showError(e) {
      this.$emit('showError', e);
    },
    handleScroll() {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > this.lastScrollTop) {
        this.scrollDirection = 'down';
      } else {
        this.scrollDirection = 'up';
      }
      this.lastScrollTop = st <= 0 ? 0 : st;

      const button = document.querySelector('.floating-button');
      if (window.scrollY >= 200 && this.scrollDirection === 'down') {
        const position = window
          .getComputedStyle(button, null)
          .getPropertyValue('position');
        if (position === 'absolute') {
          button.style.position = 'fixed';
          this.moveX(button, window.innerWidth - 70);
          this.moveY(button, window.innerHeight - 80);
          this.moveSize(button, 60);
          this.moveText(button, 0.5);
          this.moveOpacity(1, 'block');
        }
      }
      if (window.scrollY <= 200 && this.scrollDirection === 'up') {
        const position = window
          .getComputedStyle(button, null)
          .getPropertyValue('position');
        if (position === 'fixed') {
          button.style.position = 'absolute';
          this.moveX(button, this.initialButtonPos.x);
          this.moveY(button, this.initialButtonPos.y);
          this.moveSize(button, this.initialButtonPos.width);
          this.moveText(button, this.initialButtonPos.fontSize);
          this.moveOpacity(0, 'none');
        }
      }
    },
    moveX(buttonElement, pos) {
      const button = buttonElement;
      const fns = {
        down: () => button.offsetLeft >= pos,
        up: () => button.offsetLeft <= pos,
      };
      const movingValues = {
        down: 16,
        up: -16,
      };
      const timerWidth = setInterval(() => {
        if (fns[this.scrollDirection]()) {
          clearInterval(timerWidth);
        } else {
          const value = movingValues[this.scrollDirection];
          button.style.left = `${button.offsetLeft + value}px`;
        }
      }, 20);
    },
    moveY(buttonElement, pos) {
      const button = buttonElement;
      const fns = {
        down: () => button.offsetTop >= pos,
        up: () => button.offsetTop <= pos,
      };
      const movingValues = {
        down: 16,
        up: -16,
      };
      const timerHeight = setInterval(() => {
        if (fns[this.scrollDirection]()) {
          clearInterval(timerHeight);
        } else {
          const value = movingValues[this.scrollDirection];
          button.style.top = `${button.offsetTop + value}px`;
        }
      }, 20);
    },
    moveSize(buttonElement, size) {
      const button = buttonElement;
      const fns = {
        down: () => button.getBoundingClientRect().width <= size,
        up: () => button.getBoundingClientRect().width >= size,
      };
      const movingValues = {
        down: -4,
        up: 4,
      };
      const timeSizeTimer = setInterval(() => {
        if (fns[this.scrollDirection]()) {
          clearInterval(timeSizeTimer);
        } else {
          const buttonWidth = button.getBoundingClientRect().width;
          const value = movingValues[this.scrollDirection];
          button.style.width = `${buttonWidth + value}px`;
        }
      }, 20);
    },
    moveText(buttonElement, size) {
      const button = buttonElement;
      const fns = {
        down: () => {
          const fontSize = window
            .getComputedStyle(button, null)
            .getPropertyValue('font-size');
          return parseFloat(fontSize) <= size;
        },
        up: () => {
          const fontSize = window
            .getComputedStyle(button, null)
            .getPropertyValue('font-size');
          return parseFloat(fontSize) >= size;
        },
      };
      const movingValues = {
        down: -0.5,
        up: 0.5,
      };
      const timeTextTimer = setInterval(() => {
        if (fns[this.scrollDirection]()) {
          /* eslint-disable */
          button.style.fontSize =
            this.scrollDirection === 'down' ? '0px' : `${size}px`;
          /* eslint-enable */
          clearInterval(timeTextTimer);
        } else {
          let buttonFontSize = window
            .getComputedStyle(button, null)
            .getPropertyValue('font-size');
          buttonFontSize = parseFloat(buttonFontSize);
          const value = movingValues[this.scrollDirection];
          button.style.fontSize = `${buttonFontSize + value}px`;
        }
      }, 20);
    },
    moveOpacity(opacityFinal, display) {
      const mailIcon = document.querySelector('.floating-button-mail');
      mailIcon.style.position = 'absolute';
      mailIcon.style.left = '50%';
      mailIcon.style.transform = 'translateX(-50%)';
      if (this.scrollDirection === 'down') {
        mailIcon.style.display = 'block';
      }
      const fns = {
        down: () => {
          const opacity = window
            .getComputedStyle(mailIcon, null)
            .getPropertyValue('opacity');
          return parseFloat(opacity) >= opacityFinal;
        },
        up: () => {
          const opacity = window
            .getComputedStyle(mailIcon, null)
            .getPropertyValue('opacity');
          return parseFloat(opacity) <= opacityFinal;
        },
      };
      const movingValues = {
        down: 0.01,
        up: -0.01,
      };
      const mailOpacityTime = setInterval(() => {
        if (fns[this.scrollDirection]()) {
          mailIcon.style.display = display;
          clearInterval(mailOpacityTime);
        } else {
          let mailOpacity = window
            .getComputedStyle(mailIcon, null)
            .getPropertyValue('opacity');
          mailOpacity = parseFloat(mailOpacity);
          const value = movingValues[this.scrollDirection];
          mailIcon.style.opacity = `${mailOpacity + value}`;
        }
      });
    },
  },
};
