const axios = require('axios');

/* eslint-disable */
const URL_API =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000/'
    : 'https://api.nos.sh/';
/* eslint-enable */
const REDDIT_URL = 'https://www.reddit.com/r/{replace}/top.json';

export default {
  async postMessage(email, message) {
    const res = await axios.post(`${URL_API}`, { email, message });
    const { statusCode } = res.data;
    return statusCode;
  },

  async getRedditPosts(subreddits) {
    const promises = [];
    /* eslint-disable */
    subreddits.forEach((subreddit) =>
      promises.push(axios.get(REDDIT_URL.replace('{replace}', subreddit)))
    );

    const subredditPosts = await Promise.all(promises);
    let posts = [];
    subredditPosts.forEach((subredditPost) =>
      posts.push(...subredditPost.data.data.children)
    );
    /* eslint-enable */
    posts = posts.sort((a, b) => b.data.score - a.data.score);
    return posts.slice(0, 5);
  },
};
