<template>
  <v-layout fill-height align-start dark color="dark">
    <div class="d-flex justify-center align-center" style="width: 100%">
      <p class="my-4">nOS - {{ year }}</p>
    </div>
    <img style="display: none;" src="../../assets/logoTitle.png" alt="">
  </v-layout>
</template>

<script>

export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
