import API from '@/lib/api';
import ContactSVG from '@/components/shared/Contact/Contact-svg/Contact-svg.vue';

export default {
  components: {
    ContactSVG,
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      windowWidth: 0,
      loading: false,
      valid: true,
      email: '',
      message: '',
      emailRules: [
        (v) => !!v || 'Votre courriel est requis',
        /* eslint-disable */
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'Votre courriel doit être valide',
        /* eslint-enable */
      ],
      messageRules: [
        (v) => !!v || 'Un message un requis',
        (v) => (v && v.length > 0) || 'Votre message doit être valide',
      ],
      alertTimers: [],
    };
  },
  created() {
    window.addEventListener('resize', this.onResize);
    this.windowSize = window.innerWidth;
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onResize(e) {
      this.windowSize = e.srcElement.innerWidth;
    },
    getSize() {
      return this.windowSize < 960 ? 35 : 50;
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        const statusCode = await API.postMessage(this.email, this.message);
        if (statusCode === 200) {
          this.addAlertTimer('showSuccess');
          this.email = '';
          this.message = '';
          this.$emit('showSuccess', {
            state: true,
            message: 'Message envoyé avec succès.',
          });
          this.show = false;
        } else if (statusCode === 500) {
          this.addAlertTimer('showError');
          this.$emit('showError', {
            state: true,
            message:
              "Une erreur c'est produit.  Veuillez réessayer dans quelques minutes.",
          });
        }
        this.loading = false;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addAlertTimer(event) {
      this.alertTimers.push(
        setInterval(() => {
          this.$emit(event, { state: false });
          clearInterval(this.alertTimers.shift());
          // eslint-disable-next-line
        }, 1000 * 5)
      );
    },
  },
};
