<template>
  <v-app :style="cssProps" class="overflow-y-auto overflow-x-hidden">
    <Navbar />
    <v-main>
      <div class="alert-position">
        <v-alert
          v-for="(alert, i) in showSuccessAlert"
          :key="i"
          dismissible
          elevation="24"
          type="success"
          >{{ alert.message }}</v-alert
        >
        <v-alert
          v-for="(alert, i) in showErrorAlert"
          :key="i"
          dismissible
          elevation="24"
          type="error"
          >{{ alert.message }}</v-alert
        >
      </div>
      <router-view
        v-on:showSuccess="showSuccess"
        v-on:showError="showError"
      ></router-view>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navbar from '@/components/shared/Navbar.vue';
import Footer from '@/components/shared/Footer.vue';

export default {
  name: 'nOS',
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      appTitle: 'nOS',
      showSuccessAlert: [],
      showErrorAlert: [],
    };
  },
  computed: {
    cssProps() {
      const themeColors = {};
      Object.keys(this.$vuetify.theme.themes.dark).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.dark[color];
      });
      return themeColors;
    },
  },
  methods: {
    showSuccess(e) {
      if (e.state) {
        this.showSuccessAlert.push({ state: e.state, message: e.message });
      } else {
        this.showSuccessAlert.shift();
      }
    },
    showError(e) {
      if (e.state) {
        this.showErrorAlert.push({ state: e.state, message: e.message });
      } else {
        this.showErrorAlert.shift();
      }
    },
  },
};
</script>

<style>
@import './styles.scss';
</style>
