import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'nOS | Explorez la technologie',
    },
  },
  // {
  //   path: '/blog',
  //   name: 'Blog',
  //   component: () => import('../views/Blog/Blog.vue'),
  //   meta: {
  //     title: 'Blog',
  //   },
  // },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, _, next) => {
  document.title = to.meta.title;
  next();
});

export default router;
